import { textVariants } from "../text/textVariants";

const gridMaxWidth = 1920;

const breakpoints = ["576px", "768px", "1040px", "1200px"];

const mediaQueries = {
    xs: `(max-width: ${breakpoints[0]})`,
    sm: `(min-width: ${breakpoints[0]})`,
    md: `(min-width: ${breakpoints[1]})`,
    lg: `(min-width: ${breakpoints[2]})`,
    xl: `(min-width: ${breakpoints[3]})`
};

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768];

const fonts = {
    primary: "'Nexa', sans-serif",
    arial: "Arial Narrow, Arial, sans-serif"
};

/*
    Font Sizes
    Increments by an increasing step every 4 values.
    ex. [12,   14, 16, 18, 20,    24, 28, 32, 36,    42, 48, 54, 60,     68, 76, 84, 92,    102, 112, 122, 132]
*/
const fontSizes = [12];
let step = 0;
// eslint-disable-next-line id-length,no-plusplus
for (let i = 0; i < 20; i++) {
    if (i % 4 === 0) {
        step += 2;
    }
    fontSizes.push(fontSizes[fontSizes.length - 1] + step);
}

const fontWeights = {
    light: 300,
    regular: 400,
    bold: 700,
    heavy: 800,
    black: 900
};
const lineHeights = [0.875, 0.925, 1, 1.125, 1.2, 1.3, 1.5];
const letterSpacings = ["-0.001em", "-0.01em", "0.0875em", "0.25em", "0.5em"];

/**
 COLORS
 To add a new array of shades:
 1) Go to: https://maketintsandshades.com
 2) Enter the root color.
 3) Paste this into the console.
 var colors = $('.hex-color')
 .map((i, x) => x.getAttribute('style').replace('background-color:', ''))
 .toArray();
 var indices = [19, 17, 15, 13, 11, 2, 4, 6, 8];
 indices.reduce((acc, index) => {
 acc.push(colors[index]);
 return acc;
 }, []);
 4) copy the outputted array and paste here.
 */
const colors = {
    black: "#000000",
    white: "#ffffff",

    gray: "#6e6e6e",
    grays: [
        "#f9f9f9",
        "#ececec",
        "#d4d4d4",
        "#a7a7a7",
        "#6e6e6e",
        "#585858",
        "#424242",
        "#2f2f2f",
        "#161616"
    ],

    plateGray: "#5a5e5a",
    plateGrays: [
        "#efefef",
        "#cecfce",
        "#adafad",
        "#7b7e7b",
        "#5a5e5a",
        "#484b48",
        "#363836",
        "#242624",
        "#121312"
    ],

    onGray: "#ded7d2",
    onGrays: [
        "#f8f7f6",
        "#f2efed",
        "#ebe7e4",
        "#e5dfdb",
        "#ded7d2",
        "#b2aca8",
        "#85817e",
        "#595654",
        "#2c2b2a"
    ],

    sunwashGold: "#d1b16e",
    sunwashGolds: [
        "#f6efe2",
        "#ede0c5",
        "#e3d0a8",
        "#dac18b",
        "#d1b16e",
        "#a78e58",
        "#7d6a42",
        "#54472c",
        "#2a2316"
    ],

    competitionRed: "#FF433C",
    competitionReds: [
        "#ffd9d8",
        "#ffb4b1",
        "#ff8e8a",
        "#ff6963",
        "#ff433c",
        "#cc3630",
        "#992824",
        "#661b18",
        "#330d0c"
    ],

    gameRed: "#c74d55",
    gameReds: [
        "#f7ddde",
        "#efbabe",
        "#e7989d",
        "#df757d",
        "#d7535c",
        "#ac424a",
        "#813237",
        "#562125",
        "#2b1112"
    ],

    trainingBlue: "#528799",
    trainingBlues: [
        "#dce7eb",
        "#bacfd6",
        "#97b7c2",
        "#759fad",
        "#528799",
        "#426c7a",
        "#31515c",
        "#21363d",
        "#101b1f"
    ],

    earthGreen: "#80987b",
    earthGreens: [
        "#e6eae5",
        "#ccd6ca",
        "#b3c1b0",
        "#99ad95",
        "#80987b",
        "#667a62",
        "#4d5b4a",
        "#333d31",
        "#1a1e19"
    ],

    calmPurple: "#8b89b5",
    calmPurples: [
        "#e8e7f0",
        "#d1d0e1",
        "#b9b8d3",
        "#a2a1c4",
        "#8b89b5",
        "#6f6e91",
        "#53526d",
        "#383748",
        "#1c1b24"
    ],

    optimoOrange: "#f15f3c",
    optimoOranges: [
        "#fcdfd8",
        "#f9bfb1",
        "#f79f8a",
        "#f47f63",
        "#f15f3c",
        "#c14c30",
        "#913924",
        "#602618",
        "#30130c"
    ],

    riverTeal: "#70c7ae",
    riverTeals: [
        "#e2f4ef",
        "#c6e9df",
        "#a9ddce",
        "#8dd2be",
        "#70c7ae",
        "#5a9f8b",
        "#437768",
        "#2d5046",
        "#162823"
    ]
};

// ReactIconBase name is specific to react-icons lib.  don't change.
const reactIconBase = {
    color: colors.grays[4],
    size: 18
};

const variants = {
    text: {
        variants: textVariants
    }
};

const base: { [index: string]: any } = {
    gridMaxWidth,
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    space,
    reactIconBase,
    mediaQueries,
    variants
};

export default base;
